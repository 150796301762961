import React from "react";
import { Box, Button } from "@mui/material";
import googleplay from "../../assets/images/google-play.png";
import appstore from "../../assets/images/app-store.png";
import mobileapp from "../../assets/images/mobileapp.png";

const AppComingSoon = () => {
  return (
    <div>
      <div className="common-app-flex">
        <div className="width-app-store">
          <div className="mobile-app-img-m">
            <img src={mobileapp} />
          </div>
        </div>
        <div className="width-app-store">
          <p className="common-title m-t-0">Download Now</p>
          <p className="app-sublabel m-tb-30">
            GET <b>EXCITING OFFERS</b> ON YOUR <b>1ST ORDER</b> THROUGH OUR APP
          </p>
          <a
            href="https://apps.apple.com/in/app/mandigate/id6741684474"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button sx={{ p: 0 }}>
              <img
                src={appstore}
                alt="App Store"
                style={{ width: "150px", maxWidth: "100%", marginRight: "20px" }}
              />
            </Button>
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.mandigate"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button sx={{ p: 0 }}>
              <img
                src={googleplay}
                alt="Google Play"
                style={{ width: "150px", maxWidth: "100%" }}
              />
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default AppComingSoon;
