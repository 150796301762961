import React from "react";
import delivery from "../../assets/images/delivery.png"; // Update path accordingly

const Delivery = () => {
    return (
        <section className="">
            <div className="common-flex-delivery-section">
                <div className=" common-width-50 pdding-right-40">
                    <div className="discover-img-responsive">
                        <img src={delivery} alt="" />
                    </div>

                </div>
                <div className="common-width-50 pdding-left-40 mobil-m-delevery">
                    <h2 className="common-title m-t-0">
                        Quality Packaging, Reliable Delivery
                    </h2>
                    <p className="common-subtitle">
                    Presentation matters as much as quality, and we believe in delivering both. That’s why we use eco-friendly packaging to keep your fresh produce safe during transit. With our fast and reliable delivery service, you can count on your order arriving in perfect condition every time
                    </p>
                </div>

            </div>
        </section>
    );
};

export default Delivery;
