import React from "react";
import { motion } from "framer-motion";
import HeroBanner from "./HeroBanner";
import Delivery from "../Delivery/Delivery";
import SliderComponent from "../../Components/Slider/Slider";
import OurApproach from "../OurApproach/OurApproach";
import ContactForm from "../Contact/ContactForm";
import AppComingSoon from "../AppComingSoon/AppComingSoon";
import WhatWeDo from "../VideoSection/VideoSection";
import Explore from "../Explore/Explore";
import ClientLogoSlider from "../../Components/Slider/ClientLogoSlider";
import About from "../About/About";

const fadeInVariants = {
  hidden: { opacity: 0, y: 30 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
};

const Home = () => {
  return (
    <section>
      <section className="home-banner-bg banner-m-tb">
        <section className="">
          <motion.div id="home" variants={fadeInVariants} initial="hidden" animate="visible">
            <HeroBanner />
          </motion.div>
          </section>
      </section>
      <section className="main-body-section">
        <motion.div className="common-m-tb" id="about" variants={fadeInVariants} initial="hidden" whileInView="visible" viewport={{ once: true }}>
          <About />
        </motion.div>

        <motion.div className="common-m-tb" id="what-we-do" variants={fadeInVariants} initial="hidden" whileInView="visible" viewport={{ once: true }}>
          <WhatWeDo />
        </motion.div>

        <motion.div className="common-m-tb" variants={fadeInVariants} initial="hidden" whileInView="visible" viewport={{ once: true }}>
          <Delivery />
        </motion.div>

        <motion.div className="common-m-tb" id="our-approach" variants={fadeInVariants} initial="hidden" whileInView="visible" viewport={{ once: true }}>
          <OurApproach />
        </motion.div>
        <motion.div className="common-m-tb" variants={fadeInVariants} initial="hidden" whileInView="visible" viewport={{ once: true }}>
          <Explore />
        </motion.div>
        </section>
        <section className="bg-client">
        <section className="main-body-section">
          <motion.div className="common-m-tb" id="our-clients" variants={fadeInVariants} initial="hidden" whileInView="visible" viewport={{ once: true }}>
            < ClientLogoSlider />
          </motion.div>
        </section>
        </section>
        <section className="main-body-section">
        <motion.div className="common-m-tb" id="testimonials" variants={fadeInVariants} initial="hidden" whileInView="visible" viewport={{ once: true }}>
          <SliderComponent />
        </motion.div>

        <motion.div className="common-m-tb" id="app-store" variants={fadeInVariants} initial="hidden" whileInView="visible" viewport={{ once: true }}>
          <AppComingSoon />
        </motion.div>

        <motion.div className="common-m-tb" id="contact" variants={fadeInVariants} initial="hidden" whileInView="visible" viewport={{ once: true }}>
          <ContactForm />
        </motion.div>
       </section>
    </section >
  );
};

export default Home;
