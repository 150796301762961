import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Register from "./Pages/Register/Register";
import Login from "./Pages/Login/Login";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import Dashboard from "./Pages/Dashboard/Dashboard";
import { useAuth, AuthProvider } from "./context/AuthProvider";
import Contact from "./Pages/Contact/Contact";
import VideoSection from "./Pages/VideoSection/VideoSection.js";
import OurApproach from "./Pages/OurApproach/OurApproach";
import OurCommitments from "./Pages/OurCommitments/OurCommitments";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop.js";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy.js";
import About from "./Pages/About/About.js";
import AppRedirect from "./Pages/AppLink/AppRedirect.js";


const ProtectedRoute = ({ element }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? element : <Navigate to="/login" />;
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Header />
        {/* <div style={{ minHeight: "100vh", paddingBottom: "50px" }}> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/our-approach" element={<OurApproach />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/app-redirect" element={<AppRedirect />} />

          {/* <Route path="/contact" element={<Contact />} />
            <Route path="/register" element={<Register />} /> */}
          <Route path="/our-commitments" element={<OurCommitments />} />
          {/* <Route path="/login" element={<Login />} /> */}
          {/* <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} /> */}
        </Routes>
        {/* </div> */}
        <ScrollToTop />
        <Footer />
      </Router>

    </AuthProvider>
  );
};

export default App;
