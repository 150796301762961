import React from "react";
import './privacy.css'
const PrivacyPolicy = () => {
    return (
        <section className="privacy-margin-tb">
            <section className="main-body-privacy">
                <p className="common-title text-center">
                    Privacy Policy
                </p>
                <p className="common-subtitle">
                    Mandigate Pvt. Ltd manages the online trade linkage platform www.mandigate.com. We respect user privacy and are committed to protecting personal information. This Privacy Policy outlines our data collection, usage, and security measures.
                </p>
                <div className="user-info">
                    <p className="common-title text-center">
                        User Information
                    </p>
                    <p className="common-subtitle">
                        This policy applies to both registered users and visitors. We may collect the following personal information:
                    </p>
                    <ul className="list-data-privacy">
                        <li>
                            Name
                        </li>
                        <li>
                            Email address
                        </li>
                        <li>
                            Mobile number
                        </li>
                        <li>
                            PIN code
                        </li>
                        <li>
                            Address
                        </li>
                    </ul>
                    <p className="common-subtitle">
                        This information helps us enhance user experience and improve our services.
                    </p>
                </div>
                <div className="user-info">
                    <p className="common-title text-center">
                        Use of Demographic and Profile Data
                    </p>
                    <p className="common-subtitle">
                        We use personal information for:
                    </p>
                    <ul className="list-data-privacy">
                        <li>
                            Providing requested services
                        </li>
                        <li>
                            Troubleshooting issues
                        </li>
                        <li>
                            Customizing user experience
                        </li>
                        <li>
                            Enhancing our website and services
                        </li>

                    </ul>
                    <p className="common-subtitle">
                        Your IP address may be collected for security and website optimization purposes.
                    </p>
                </div>
                <div className="user-info">
                    <p className="common-title text-center">
                        Cookies
                    </p>
                    <p className="common-subtitle">
                        Cookies are used to:
                    </p>
                    <ul className="list-data-privacy">
                        <li>
                            Enhance site responsiveness
                        </li>
                        <li>
                            Store user preferences
                        </li>
                        <li>
                            Analyze site traffic patterns
                        </li>

                    </ul>
                    <p className="common-subtitle">
                        Cookies do not contain personally identifiable information unless voluntarily provided.
                    </p>
                </div>
                <div className="user-info">
                    <p className="common-title text-center">
                        Link to Third-Party Sites
                    </p>
                    <p className="common-subtitle">
                        Our website may contain links to external sites. These sites have their own privacy policies, and we are not responsible for their practices.
                    </p>
                </div>
                <div className="user-info">
                    <p className="common-title text-center">
                        Information Sharing
                    </p>
                    <p className="common-subtitle">
                        We do not share personal information with third parties except:
                    </p>
                    <ul className="list-data-privacy">
                        <li>
                            When required by law.
                        </li>
                        <li>
                            Within our corporate group for operational purposes.
                        </li>
                        <li>
                            In cases of mergers, acquisitions, or business restructuring.
                        </li>
                    </ul>
                </div>
                <div className="user-info">
                    <p className="common-title text-center">
                        Accessing and Updating Personal Information
                    </p>
                    <p className="common-subtitle">
                        Users can request access to their personal data. We strive to ensure accuracy and allow updates or deletions where applicable.
                    </p>

                </div>
                <div className="user-info">
                    <p className="common-title text-center">
                        Information Security
                    </p>
                    <p className="common-subtitle">
                        We implement security measures, including:
                    </p>
                    <ul className="list-data-privacy">
                        <li>
                            Data encryption
                        </li>
                        <li>
                            Secure storage
                        </li>
                        <li>
                            Restricted access to personal information
                        </li>
                    </ul>
                    <p className="common-subtitle">
                        Despite these measures, no system is completely secure. Users should exercise caution while sharing personal information online.
                    </p>
                </div>
                <div className="user-info">
                    <p className="common-title text-center">
                        Cancellation and Return Policy
                    </p>
                    <ul className="list-data-privacy">
                        <li>
                            We reserve the right to cancel or remove content at our discretion.
                        </li>
                        <li>
                            No refunds for Platinum listing packages unless payment is made via cheque or demand draft.
                        </li>
                        <li>
                            Cancellations must be requested within one month of purchase.
                        </li>
                    </ul>
                    <p className="common-subtitle">
                        Despite these measures, no system is completely secure. Users should exercise caution while sharing personal information online.
                    </p>
                </div>
                <p className="common-subtitle">
                    For any privacy-related concerns, contact us at: <b>grievance@Mandigate.com</b>
                </p>
            </section>
        </section>

    );
};


export default PrivacyPolicy;