import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link as ScrollLink, Events, scrollSpy } from "react-scroll";
import { motion, AnimatePresence } from "framer-motion"; // Import motion components
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Typography,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../../assets/images/logo.svg";
import "./header.css";

const desktopHeader = [
  { label: "Home", path: "home" },
  { label: "About", path: "about" },
  { label: "Our Clients", path: "our-clients" },
  { label: "Contact", path: "contact" },
]
const mobileHeader = [
  { label: "Home", path: "home" },
  { label: "About", path: "about" },
  { label: "Our Clients", path: "our-clients" },
  { label: "Contact", path: "contact" },
]
const Header = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("home");

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    Events.scrollEvent.register("begin", (to) => {
      setActiveLink(to);
    });

    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove("begin");
    };
  }, []);

  return (
    <>
      <section className="header-full-bg-color">
        <AppBar position="fixed" sx={{
          background: "linear-gradient(180deg, #F2F9F1 0%, #F5FAF4 33%, #F6FBF5 66%, #f3f9f2 100%)",
          boxShadow: "none",
          zIndex: 1300,
          height: { xs: "100px", md: "105px" }, 
          lineHeight: { xs: "100px", md: "105px" } 
        }}
        >
          <section className="main-body-section">
            <Toolbar sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingLeft: '0px !important  ', paddingRight: '0px !important' }} >

              {/* Logo */}
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Button component={RouterLink} to="/">
                  <Box
                    sx={{
                      width: { xs: "200px", sm: "300px", md: "400px", lg: "400px" },
                      height: { xs: "80px", sm: "96px", md: "96px", lg: "96px" },
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      mx: "auto",
                    }}
                  >
                    <img
                      src={logo}
                      alt="MandiGate Logo"
                      className="logo-mobile"
                      
                    />
                  </Box>

                </Button>
              </Box>

              {/* Desktop Navigation */}
              <Box sx={{ display: { xs: "none", md: "flex" }, gap: "50px" }}>
                {
                  desktopHeader.map((item, index) => (
                    <ScrollLink
                      key={index}
                      to={item.path}
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                      className={`header-link-label ${activeLink === item.path ? "active-link" : ""}`}
                      style={{ cursor: "pointer", paddingBottom: "5px" }}
                    >
                      <p className="header-link-label header-m-link">{item.label}</p>
                    </ScrollLink>
                  ))}
              </Box>

              {/* Right - Buttons & Mobile Menu */}
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ScrollLink to="app-store" smooth={true} offset={-120} duration={500}>
                  <button className="login-btn-style">
                    Download Now
                  </button>
                </ScrollLink>
                {/* Mobile Menu Icon */}
                <IconButton
                  onClick={handleDrawerToggle}
                  sx={{ display: { xs: "block", md: "none" }, color: "#026D0B" }}
                >
                  <MenuIcon sx={{ fontSize: 40 }} />
                </IconButton>
              </Box>

            </Toolbar>
          </section>
        </AppBar>
      </section>
      {/* Animated Mobile Drawer */}
      <AnimatePresence>
        {mobileOpen && (
          <motion.div
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            transition={{ duration: 0.4, ease: "easeInOut" }}
            style={{
              position: "fixed",
              top: 0,
              right: 0,
              width: "240px",
              height: "100vh",
              background: "#ffffff",
              boxShadow: "-5px 0 10px rgba(0, 0, 0, 0.2)",
              zIndex: 1400,
            }}
          >
            <Box display="flex" justifyContent="flex-end" p={1}>
              <IconButton onClick={handleDrawerToggle} sx={{ color: "#fff", bgcolor: "#026D0B" }}>
                <CloseIcon />
              </IconButton>
            </Box>

            <List sx={{ p: 2 }}>
              {
                mobileHeader.map((item, index) => (
                  <ListItem key={index} button sx={{ py: 1 }}>
                    <ScrollLink
                      to={item.path}
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                      className={`header-link-label ${activeLink === item.path ? "active-link" : ""}`}
                      style={{ width: "100%", cursor: "pointer", color: "black", textDecoration: "none" }}
                      onClick={() => {
                        setActiveLink(item.path);
                        handleDrawerToggle();
                      }}
                    >
                      <ListItemText primary={item.label} primaryTypographyProps={{ fontSize: 18 }} />
                    </ScrollLink>
                  </ListItem>
                ))}
            </List>

            <Divider sx={{ bgcolor: "gray", my: 2 }} />

            {/* Buttons Section */}
            <Box display="flex" flexDirection="column" gap={2} px={2} pb={3}>
              <ScrollLink to="app-store" smooth={true} offset={-120} duration={500} onClick={handleDrawerToggle}>
                <Button className="download-btn" sx={{ color: "#fff" }}>
                  Download
                </Button>
              </ScrollLink>
            </Box>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Prevent content from being hidden under the fixed header */}
      <Box sx={{ height: "71px" }} />
    </>
  );
};

export default Header;
