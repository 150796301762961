import { useEffect } from 'react';

const AppRedirect = () => {
    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(userAgent)) {
            window.location.href = "https://play.google.com/store/apps/details?id=com.mandigate";
        } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
            window.location.href = "https://apps.apple.com/in/app/mandigate/id6741684474";
        } else {
            // If not mobile, show a landing page
            window.location.href = "/";
        }
    }, []);

    return <p>Redirecting...</p>; // This is optional, for a better user experience
};

export default AppRedirect;
