import { Box, Typography, TextField, Card, Avatar, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { LocationOn, Phone, Email } from "@mui/icons-material";
import { motion } from "framer-motion";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import map from "../../assets/images/map.png";

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  phone: yup.string().matches(/^[0-9]+$/, "Must be a number"),
  reason: yup.string().required("Please select a category"),
  message: yup.string().required("Message is required"),
});

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      const baseUrl = window.location.href.includes('dev') ? 'https://dev.mandigate.com' : 'https://mandigate.com';
      const response = await fetch(`${baseUrl}/sendmail.php`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(data),
      });

      if (response.ok) {
        alert('Message sent successfully');
      } else {
        alert('Failed to send message');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to send message');
    }
  };

  return (
    <Box sx={{ bgcolor: '#00490C', boxShadow: "0px 20px 25px -5px rgba(0, 0, 0, 0.10), 0px 0px 10px 0px #FFF inset", color: "white", py: 6, px: 3, borderRadius: '20px' }}>
      <Box textAlign="center" mb={4}>
        <p className="common-title contact-m-t" style={{ color: '#ffffff' }}>Contact Details</p>
        <p className="common-subtitle contact-sub-m-t" style={{ color: '#ffffff' }}>Get in touch with us for any inquiries or support.</p>
      </Box>
      <Box display="flex" flexDirection={{ xs: "column", md: "row" }} gap={4} mx="auto">
        <Box flex={1} component={motion.div} initial={{ opacity: 0, x: -50 }} animate={{ opacity: 1, x: 0 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField fullWidth label="Name*" variant="outlined" sx={{ mb: 2, bgcolor: "#f9f9f9", borderRadius: 4, "& fieldset": { border: "none" } }} {...register("name")} error={!!errors.name} helperText={errors.name?.message} />
            <TextField fullWidth label="Email*" variant="outlined" sx={{ mb: 2, bgcolor: "#f9f9f9", borderRadius: 4, "& fieldset": { border: "none" } }} {...register("email")} error={!!errors.email} helperText={errors.email?.message} />
            <TextField fullWidth label="Phone" variant="outlined" sx={{ mb: 2, bgcolor: "#f9f9f9", borderRadius: 4, "& fieldset": { border: "none" } }} {...register("phone")} error={!!errors.phone} helperText={errors.phone?.message} />

            {/* Select Dropdown for Reason */}
            <FormControl fullWidth sx={{ mb: 2, bgcolor: "#f9f9f9", borderRadius: 4 }}>
              <InputLabel>Select Category*</InputLabel>
              <Select defaultValue="" onChange={(e) => setValue("reason", e.target.value)} error={!!errors.reason}>
                <MenuItem value="Payment">Payment</MenuItem>
                <MenuItem value="Quality">Quality</MenuItem>
                <MenuItem value="Delivery">Delivery</MenuItem>
                <MenuItem value="Others">Others</MenuItem>
              </Select>
              {errors.reason && <Typography color="error" variant="body2">{errors.reason.message}</Typography>}
            </FormControl>

            <TextField fullWidth label="Message" multiline rows={4} variant="outlined" sx={{ bgcolor: "#f9f9f9", borderRadius: 4, height: "154px", "& fieldset": { border: "none" } }} {...register("message")} error={!!errors.message} helperText={errors.message?.message} />
            <button className="download-btn m-btn-contact" type="submit">Contact Us</button>
          </form>
        </Box>
        <Box flex={1} display="flex" flexDirection="column" gap={2}>
          <div className="map"><img src={map} alt="Map Location" /></div>
          <Card sx={{ bgcolor: "#f9f9f9", color: "#000", display: "flex", alignItems: "center", p: 2, borderRadius: 4 }}>
            <Avatar sx={{ bgcolor: "#00490C", color: "#fff", mr: 2 }}><LocationOn /></Avatar>
            <Typography variant="body1">C-1/68, Sector 5, Rohini, Delhi, 110085</Typography>
          </Card>
          <Box display="flex" flexDirection={{ xs: "column", sm: "row" }} gap={2}>
            <Card sx={{ bgcolor: "#f9f9f9", color: "#000", display: "flex", alignItems: "center", p: 2, borderRadius: 4, flex: 1 }}>
              <Avatar sx={{ bgcolor: "#00490C", color: "#fff", mr: 2 }}><Phone /></Avatar>
              <Typography variant="body1">(+91) 9220638055</Typography>
            </Card>
            <Card sx={{ bgcolor: "#f9f9f9", color: "#000", display: "flex", alignItems: "center", p: 2, borderRadius: 4, flex: 1 }}>
              <Avatar sx={{ bgcolor: "#00490C", color: "#fff", mr: 2 }}><Email /></Avatar>
              <Typography variant="body1">support@mandigate.com</Typography>
            </Card>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ContactForm;
