import React from "react";
import mandigateVideo from "../../assets/images/mandigate.mp4"; // Import video
import { Link } from "react-scroll"; // Import smooth scroll

const VideoSection = () => {
    return (
        <section className="">
            <div className="common-flex">
                {/* Left Content Section */}
                <div className="common-width-50 pdding-right-40">
                    <h2 className="common-title m-t-0">
                        Bridging Farmers and Consumers with Fresh Produce
                    </h2>
                    <p className="common-subtitle">
                        We are dedicated to delivering fresh, organic, and healthy
                        fruits and vegetables directly from farmers to consumers. With a
                        diverse range of over 50 products, we ensure a reliable supply chain,
                        superior packaging, and efficient delivery services to provide the best
                        quality produce.
                    </p>
                    <div className="flex-section">
                        <div className="discover-m">
                            <p className="number-label common-border">10+</p>
                            <p className="number-subtitle">Years of Excellence</p>
                        </div>

                        <div className="discover-m">
                            <p className="number-label common-border">150+</p>
                            <p className="number-subtitle">Curated Products</p>
                        </div>

                        <div className="discover-m">
                            <p className="number-label">25+</p>
                            <p className="number-subtitle">Satisfied Clients</p>
                        </div>
                    </div>
                </div>

                {/* Right Video Section */}
                <div className="common-width-50 pdding-left-40 m-tmobile-35">
                    <div className="video-container">
                        <video className="responsive-video" autoPlay loop muted playsInline controls>
                            <source src={mandigateVideo} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default VideoSection;
