import React, { useEffect, useState } from "react";
import { CheckCircle } from "@mui/icons-material";
import { Link } from "react-scroll"; // Import smooth scroll
const About = () => {
  return (
    <section className=" common-flex">
      <div className="mission-m-left">
        <p className=" mission-item">
          <CheckCircle fontSize="small" style={{ verticalAlign: 'sub', color: "white", backgroundColor: "green", borderRadius: "50%", padding: "3px" }} />
          <span>
            <p className="mission-common-title">Our Mission</p>
            <p className="mission-subtitle">
              To revolutionize the way fresh produce reaches customers by ensuring quality, sustainability, and affordability, while empowering farmers with better opportunities and fair pricing.
            </p>

          </span>
        </p>

        <p className="mission-item mission-m-t">
          <CheckCircle fontSize="small" style={{ verticalAlign: 'sub', color: "white", backgroundColor: "green", borderRadius: "50%", padding: "3px" }} />
          <span>
            <p className="mission-common-title">Our Vision</p>
            <p className="mission-subtitle">
              To create a sustainable and transparent ecosystem that benefits both farmers and consumers.
            </p>
          </span>
        </p>
        <p className="mission-item mission-m-t">
          <CheckCircle fontSize="small" style={{ verticalAlign: 'sub', color: "white", backgroundColor: "green", borderRadius: "50%", padding: "3px" }} />
          <span>
            <p className="mission-common-title">Our Journey</p>
            <p className="mission-subtitle">
              Founded in 2016, MandiGate Private Limited connects farmers and consumers by providing fresh, healthy produce. We ensure quality, efficiency, and a seamless procurement experience.
            </p>
          </span>
        </p>
        <p className="mission-item mission-m-t">
          <CheckCircle fontSize="small" style={{ verticalAlign: 'sub', color: "white", backgroundColor: "green", borderRadius: "50%", padding: "3px" }} />
          <span>
            <p className="mission-common-title">Our Leadership</p>
            <p className="mission-subtitle">
              MandiGate Private Limited was founded by Mr. Yogesh Saini, a visionary entrepreneur with a passion for revolutionizing the agricultural supply chain. Under his leadership, we have grown into a trusted name known for quality, reliability, and innovation in the fresh produce sector.
            </p>
          </span>
        </p>
      </div>
      <div className="right-container-common pdding-left-40">
        <div className="who-are-width">
          <p className="common-title who-are-m-t">Who We Are?</p>
          <div>
            <p className="common-subtitle">
              We are more than just a supplier of fresh produce; we are a movement towards healthier living and sustainable farming. Our foundation is built on trust, quality, and an unwavering commitment to delivering the best farm-fresh produce to our customers.
            </p>
            
          </div>
        </div>

      </div>
    </section>
  );
};

export default About;
