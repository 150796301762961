import React from "react";
import Slider from "react-slick";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"; // Import icons for arrows
import heroimg from "../../assets/images/heroimg.png";
import first from "../../assets/images/first.png";
import second from "../../assets/images/second.png";
import third from "../../assets/images/third.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./herobanner.css";

// Custom arrow components
const NextArrow = ({ onClick }) => (
  <div className="slick-arrow next" onClick={onClick}>
    <FaChevronRight />
  </div>
);

const PrevArrow = ({ onClick }) => (
  <div className="slick-arrow prev" onClick={onClick}>
    <FaChevronLeft />
  </div>
);

const HeroBanner = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div className="main-section-hero ">
      <Slider {...settings}>
        <div>
          {/* <h2 className="common-title hero-m-t hero-p-t hero-textlign">
            Redefine Shopping Bliss <br /> With 
            <span className="green-colr"> MandiGate</span>
          </h2> */}
          <div className="hero-img-center">
            <img src={heroimg} alt="Hero" />
          </div>
        </div>
        <div>
          {/* <h2 className="common-title hero-m-t hero-p-t hero-textlign">
            Best Deals for <br /> <span className="green-colr">Fresh Products</span>
          </h2> */}
          <div className="hero-img-center">
            <img src={first} alt="Hero 2" />
          </div>
        </div>
        <div>
          {/* <h2 className="common-title hero-m-t hero-p-t hero-textlign">
            Best Deals for <br /><span className="green-colr">Fresh Products</span>
          </h2> */}
          <div className="hero-img-center">
            <img src={second} alt="Hero 2" />
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default HeroBanner;
