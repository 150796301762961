import { useState } from "react";
import { Box, Button, Container, Grid2, TextField, Typography, Snackbar, CircularProgress } from "@mui/material";
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";
import axios from "axios";

// Custom Formik MUI TextField Component
const CustomTextField = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <TextField
      {...field}
      fullWidth
      label={label}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error}
    />
  );
};

// Validation Schema
const validationSchema = Yup.object().shape({
  name: Yup.string().min(3, "Too Short!").required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  message: Yup.string().min(10, "Message too short").required("Message is required"),
});

const Contact = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const response = await axios.post("https://jsonplaceholder.typicode.com/posts", values);
      console.log("Response:", response.data);
      setOpen(true);
      resetForm();
    } catch (error) {
      setErrorMsg("Failed to send message. Please try again later.");
      console.error("Error submitting form:", error);
    }
    setLoading(false);
  };

  return (
    <Container sx={{ mt: 5, mb: 5 }}>
      {/* Header Section */}
      <Box sx={{ textAlign: "center", mb: 4 }}>
        <Typography variant="h3" fontWeight="bold" color="primary">Contact Us</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          We'd love to hear from you! Please fill out the form below.
        </Typography>
      </Box>

      {/* Form Section */}
      <Formik
        initialValues={{ name: "", email: "", message: "" }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Grid2 container spacing={3} justifyContent="center">
              <Grid2 item xs={12} sm={6}>
                <CustomTextField label="Name" name="name" />
              </Grid2>
              <Grid2 item xs={12} sm={6}>
                <CustomTextField label="Email" name="email" type="email" />
              </Grid2>
              <Grid2 item xs={12}>
                <CustomTextField label="Message" name="message" multiline rows={4} />
              </Grid2>

              {/* Error Message */}
              {errorMsg && (
                <Grid2 item xs={12} textAlign="center">
                  <Typography color="error">{errorMsg}</Typography>
                </Grid2>
              )}

              {/* Submit Button */}
              <Grid2 item xs={12} textAlign="center">
                <Button type="submit" variant="contained" color="primary" size="large" disabled={loading}>
                  {loading ? <CircularProgress size={24} sx={{ color: "white" }} /> : "Submit"}
                </Button>
              </Grid2>
            </Grid2>
          </Form>
        )}
      </Formik>

      {/* Success Snackbar */}
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
        message="Message Sent Successfully!"
      />
    </Container>
  );
};

export default Contact;
