import "@fontsource/inter"; // Defaults to weight 400
import "@fontsource/inter/400.css"; // Regular
import "@fontsource/inter/700.css"; // Bold
import "@fontsource/montserrat"; // Defaults to weight 400
import "@fontsource/montserrat/400.css"; // Regular
import "@fontsource/montserrat/700.css"; // Bold
import "@fontsource/roboto"; // Defaults to weight 400
import "@fontsource/roboto/300.css"; // Light
import "@fontsource/roboto/500.css"; // Medium
import "@fontsource/roboto/700.css"; // Bold

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/style/global.css'
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
