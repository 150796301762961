import { Box, Divider, Grid, Link, IconButton } from "@mui/material";
import { Link as ScrollLink } from "react-scroll"; // Import for smooth scrolling
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import logofooter from '../../assets/images/logofooter.png';

const footerLinks = [
  {
    title: "Company",
    links: [
      { text: "About Us", path: "about" }, // Use section ID instead of "/"
      { text: "Our Clients", path: "our-clients" } // Use section ID instead of "/"
    ]
  },
  {
    title: "Support",
    links: [
      { text: "Contact Us", path: "contact" },
      { text: "Help Center", path: "/" }
    ]
  }
];

const socialLinks = [
  { icon: <FacebookIcon sx={{ color: "#1877F2" }} />, label: "Facebook", path: "https://www.facebook.com/MandiGateOfficial/" },
  { icon: <InstagramIcon sx={{ color: "#E4405F" }} />, label: "Instagram", path: "https://www.instagram.com/mandigateofficial/" },
  { icon: <LinkedInIcon sx={{ color: "#0077B5" }} />, label: "LinkedIn", path: "https://in.linkedin.com/company/mandigate" }
];

const Footer = () => {
  return (
    <section className="main-body-section">
      <Box>
        <Grid container spacing={2} justifyContent="space-between" alignItems="flex-start">
          {/* Logo and About Section */}
          <Grid item xs={12} md={4} textAlign={{ xs: "center", md: "left" }}>
            <p className="footer-logo-title">
              <img src={logofooter} className="footer-logo" alt="MandiGate Logo" />
              MandiGate
            </p>
            <p className="footer-logo-sublabel">
              We deliver fresh groceries straight to your door, offering a wide variety of high-quality
              products at unbeatable prices. Shop conveniently and sustainably, every day.
            </p>
          </Grid>

          {/* Footer Links */}
          {footerLinks.map((section, index) => (
            <Grid item xs={12} sm={4} md={2} key={index} textAlign={{ xs: "center", md: "left" }}>
              <p className="footer-link-title">{section.title}</p>
              <Box display="flex" flexDirection="column" gap={1}>
                {section.links.map((link, i) => (
                  <ScrollLink 
                    to={link.path} 
                    smooth={true} 
                    duration={600} 
                    offset={-120} // Adjust if needed
                    className="footer-link" 
                    key={i}
                  >
                    {link.text}
                  </ScrollLink>
                ))}
              </Box>
            </Grid>
          ))}

          {/* Social Media Links */}
          <Grid item xs={12} sm={4} md={2} textAlign={{ xs: "center", md: "left" }}>
            <p className="footer-link-title">Connect</p>
            {socialLinks.map((social, index) => (
              <Box key={index} display="flex" alignItems="center" gap={1} mb={1} className="flex-colum-b-social">
                <IconButton href={social.path} target="_blank" rel="noopener noreferrer" sx={{ bgcolor: "white", "&:hover": { bgcolor: "#f0f0f0" } }}>
                  {social.icon}
                </IconButton>
                <p className="socail-label">{social.label}</p>
              </Box>
            ))}
          </Grid>
        </Grid>

        {/* Divider */}
        <Divider sx={{ my: 4 }} />

        {/* Bottom Section */}
        <Box display="flex" flexDirection={{ xs: "column", sm: "row" }} justifyContent="space-between" alignItems="center" textAlign="center">
          <Box className="footer-bottom-link">
            <Link className="footer-link" href="/" underline="none" sx={{ mx: 1 }}>Terms & Conditions</Link>
            <Link className="footer-link privacy-m" href="/privacy-policy" underline="none" sx={{ mx: 1 }} >Privacy Policy</Link>
          </Box>
          <p className="footer-link">© 2025 MandiGate. All rights reserved.</p>
        </Box>
      </Box>
    </section>
  );
};

export default Footer;
