import React from "react";
import approach from "../../assets/images/approach.jpeg";
const OurApproach = () => {
    return (
        <div>
            <div className="approach-main-section-bg">
                <div className="">
                    <p className="approach-title">
                        Our Approach
                    </p>
                    <p className="approach-subtitle">
                        Our approach is centered around three key pillars: quality,
                        sustainability, and customer satisfaction. We work closely with
                        farmers to ensure they follow organic and natural farming practices.
                        By leveraging technology, we optimize logistics to maintain the freshness
                        of our produce from farm to table. Every step of our process is designed
                        to ensure that our customers receive the best-quality products with minimal
                        environmental impact..
                    </p>


                </div>
            </div>
        </div>
    )
}

export default OurApproach