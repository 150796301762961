import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Avatar, Box, Typography, Button } from "@mui/material";
import { styled } from "@mui/system";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

const testimonials = [
  {
    text: "As a restaurant owner, I rely on timely deliveries and top-quality vegetables. This service has been a game-changer for my business.",
    name: "Ranjit Kumar",
    location: "Noida, UP",
    image: "https://via.placeholder.com/50",
  },
  {
    text: "Fresh produce delivered right to my doorstep! The quality and packaging are exceptional, and the prices are so reasonable. Highly recommended!",
    name: "Pankaj Chaurasia",
    location: "Ghaziabad, UP",
    image: "https://via.placeholder.com/50",
  },
  {
    text: "Fast delivery, great quality, and excellent customer support. They truly understand what Indian households need.",
    name: "Anjali. M",
    location: "Faridabad, Haryana",
    image: "https://via.placeholder.com/50",
  },
];

const TestimonialCard = styled(Box)({
  background: "linear-gradient(to bottom, #008000, #006400)",
  color: "white",
  padding: "20px",
  borderRadius: "12px",
  textAlign: "center",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "250px",
  margin: "0 10px",
});

const NameContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginTop: "10px",
  // backgroundColor: "rgba(0, 0, 0, 0.2)",
  padding: "8px 12px",
  borderRadius: "8px",
  width: "100%",
  justifyContent: "flex-start",
});

const NavigationButtons = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "20px",
  marginTop: "20px",
});

const SlickSlider = () => {
  let sliderRef = React.useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true, // Enable auto slider
    responsive: [
      { breakpoint: 1200, settings: { slidesToShow: 2 } },
      { breakpoint: 900, settings: { slidesToShow: 1 } },
    ],
  };

  return (
    <Box sx={{ overflow: "hidden", width: "100%" }}>
      <p className="textmonial-title text-center">
           Testimonials
      </p>
      <p className="textmonial-sublabel">
      See what our happy customers are saying!
      </p>
      <Slider ref={(slider) => (sliderRef = slider)} {...settings} style={{ padding: "0 5px" }}>
        {testimonials.map((item, index) => (
          <Box key={index}>
            <TestimonialCard>
              <p className="textmonial-subtitle">
                {item.text}
              </p>
              <NameContainer>
                <Avatar src={item.image} sx={{ width: 40, height: 40, marginRight: 2 }} />
                <Box >
                  <p className="textmonial-name">
                    {item.name}
                  </p>
                  <p className="textmonial-address">{item.location}</p>
                </Box>
              </NameContainer>
            </TestimonialCard>
          </Box>
        ))}
      </Slider>
      <NavigationButtons>
        <Button onClick={() => sliderRef.slickPrev()} variant="text" startIcon={<ArrowBackIos />} sx={{ color: "#000" }}>
          Prev
        </Button>
        <Button onClick={() => sliderRef.slickNext()} variant="text" endIcon={<ArrowForwardIos />} sx={{ color: "green" }}>
          Next
        </Button>
      </NavigationButtons>
    </Box>
  );
};

export default SlickSlider;
