import React from "react";
import quality from '../../assets/images/quality.png';
import reliable from '../../assets/images/reliable.png';
import susnsability from '../../assets/images/susnsability.png';
import customer from '../../assets/images/customer.png';

const Explore = () => {
    return (
        <section className="explore-flex">
            <div className="content pdding-right-40">
                <p className="common-title m-t-0">
                    Committed to Quality, Sustainability & Trust
                </p>
                <div className="explore-m-left">
                    <p className=" explore-item">
                        <img src={quality} className="common-img-width-explore" alt="quality" />
                        <span>
                            <p className="explore-common-title">Quality Assurance</p>
                            <p className="ex-subtitle">
                                We ensure the highest quality standards with fresh, carefully
                                sourced produce. Every product undergoes strict quality checks for
                                superior freshness and safety.
                            </p>

                        </span>
                    </p>

                    <p className="explore-item expl-m-t">
                        <img src={reliable} className="common-img-width-explore" alt="reliable" />
                        <span>
                            <p className="explore-common-title">Reliable Supply Chain</p>
                            <p className="ex-subtitle">
                                We maintain a robust and efficient supply chain to ensure
                                fresh produce reaches customers on time. Our streamlined logistics
                                guarantee quality and reliability at every step.
                            </p>
                        </span>
                    </p>
                </div>
            </div>

            <div className="right-explore-common pdding-left-40">
                <p className="ex-sublabel m-t-0">
                    We are dedicated to ensuring quality, sustainability, and a
                    reliable supply chain while prioritizing customer satisfaction.
                    Our commitment drives us to deliver fresh, healthy, and responsibly sourced produce.
                </p>
                <div className="explore-m-right">
                    <p className="explore-item">
                        <img src={susnsability} className="common-img-width-explore" alt="susnsability" />
                        <span>
                            <p className="explore-common-title">Sustainability</p>
                            <p className="ex-subtitle">
                                We prioritize sustainable sourcing and eco-friendly
                                practices to support farmers and protect the environment.
                            </p>
                        </span>
                    </p>

                    <p className="explore-item expl-m-t">
                        <img src={customer} className="common-img-width-explore" alt="customer" />
                        <span>
                            <p className="explore-common-title">Customer Satisfaction</p>
                            <p className="ex-subtitle">
                                We prioritize customer satisfaction by delivering fresh,
                                high-quality produce with exceptional service. Our commitment
                                to excellence ensures a seamless and reliable experience.
                            </p>
                        </span>
                    </p>
                </div>
            </div>
        </section>
    );
};

export default Explore;
