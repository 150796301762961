import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Typography } from "@mui/material";
import slide1 from "../../assets/images/clientlogo/slide1.png";
import slide2 from "../../assets/images/clientlogo/slide2.png";
import slide4 from "../../assets/images/clientlogo/slide4.png";
import slide5 from "../../assets/images/clientlogo/slide5.png";
import slide6 from "../../assets/images/clientlogo/slide6.png";
import otipy from "../../assets/images/clientlogo/otipy.png";
import hyperpure from "../../assets/images/clientlogo/hyperpure.png";
import flipcart from "../../assets/images/clientlogo/flipcart.png";
import sliderblinkit from "../../assets/images/clientlogo/sliderblinkit.png";
import zepto from "../../assets/images/clientlogo/zepto.png";
import jio from "../../assets/images/clientlogo/jio.png";
import club from "../../assets/images/clientlogo/club.png";
import bigbasket from "../../assets/images/clientlogo/bigbasket.png";
const clientLogos = [
  slide1, slide2, slide4, slide5, slide6, otipy, hyperpure, flipcart, sliderblinkit, zepto,bigbasket, club,jio
];

const ClientLogoSlider = () => {
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 500,
    arrows: false,
    dots: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: 768,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 480,
        settings: { slidesToShow: 2 },
      },
    ],
  };

  return (
    <section>
      <section className="main-body-section">
        <Box sx={{ textAlign: "center" }} >
          <p className="textmonial-title text-center">
            Our Clients
          </p>
          <p className="textmonial-sublabel">
            Trusted by <strong>leading brands</strong> for fresh and quality produce.
          </p>
          <Slider {...settings}>
            {clientLogos.map((logo, index) => (
              <Box key={index} sx={{ px: 2 }}>
                <img src={logo} alt={`Client ${index + 1}`} style={{ width: "100%", maxHeight: "80px", objectFit: "contain" }} />
              </Box>
            ))}
          </Slider>
        </Box>
      </section>
    </section>
  );
};

export default ClientLogoSlider;
