import React from "react";
import commitimg from "../../assets/images/commitimg.png"; // Update path accordingly

const OurCommitments = () => {
    return (
        <section className="">
            <div className="common-flex">
                <div className="common-width-50 pdding-right-40">
                    <h2 className="common-title m-t-0">
                           Our Commitments
                    </h2>
                    <p className="common-subtitle border-bottom-commit">
                      Quality Assurance
                    </p>
                    <p className="common-subtitle border-bottom-commit">
                      Sustainability
                    </p>
                    <p className="common-subtitle border-bottom-commit">
                      Reliable Suuply Chain
                    </p>
                    <p className="common-subtitle border-bottom-commit">
                     Customer Satisfaction
                    </p>
                </div>
                <div className="common-width-50 pdding-left-40">
                    <div className="discover-img-responsive">
                        <img src={commitimg} alt="" />
                    </div>

                </div>
            </div>
        </section>
    );
};

export default OurCommitments;
